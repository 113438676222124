import {
  all,
  call,
  put,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects'
import { compose } from 'redux'
import {
  LOCATION_CHANGE,
  replace as replaceHistory,
} from 'redux-first-history'
import { produce } from 'immer'
import actionTypes from 'reducers/actionTypes'
import { restEndpoint } from 'reducers/utils'
import {
  get,
  omit,
  omitIfBlank,
  pick,
  set,
} from 'fp/objects'
import { actions as notificationActions } from 'reducers/notifications'
import { capitalize } from 'fp/strings'
import { identity } from 'fp/utils'
import { buildUrl } from 'fp/internet'
import { contentBuilderUrl, contentViewerUrl } from 'routing/consts'
import {
  CONTENT_STATE_DRAFT,
  CONTENT_STATE_PUBLISHED,
  CONTENT_SUBTYPE_CHAPTER_SUMMARY,
  CONTENT_SUB_TYPE_STANDALONE,
  CONTENT_TYPE_BLOCK,
  CONTENT_TYPE_CHAPTER,
  CONTENT_TYPE_COURSE,
  CONTENT_TYPE_ECHO,
  CONTENT_TYPE_INTERACTIVE,
  CONTENT_TYPE_SOURCE,
  CONTENT_TYPE_UNIT,
  INTERACTIVE_TYPE_FLIPBOOK,
  INTERACTIVE_TYPE_TIMELINE,
  TOGGLE_STATE_PRESENTER_MODE,
} from 'core/consts'
import { getContentAncestry, getContentById } from 'selectors/content'
import { newlyAddedChildId } from 'hss/ContentBuilder/consts'
import { difference, first, last } from 'fp/arrays'
import { matchPathSelector } from 'selectors/routing'
import {
  getAnyAncestorIsTe,
  getCurrentViewContent,
  getNextPrevForContentViewer,
  getViewerTopLevelContent,
} from 'selectors/contentViewer'
import { getLocalSetting } from 'selectors/localSettings'
import { stateRouting } from 'selectors/index'
import { alter, assert } from '../search/squery'
import { dangerouslyCallApi } from './api'
import { actionSucceeded, failure, success } from './utils'
import { provideNewEchoWithFeatureStubs } from './echo'
import { preparePayloadForSave } from './contentSaving'

const assertArray = item => Array.isArray(item) ? item : []

export function* handleSave(action) {
  const preparedAction = yield call(preparePayloadForSave, action)
  yield call(dangerouslyCallApi, { action, ...preparedAction })
}

export function* handleSaveSuccess({ passThrough: { contentType, editing, suppressAlert = false } }) {
  if (!suppressAlert) {
    yield (put(notificationActions.addAlert({
      message: capitalize(editing
        ? `${contentType} saved`
        : `${contentType} created`),
    })))
  }
}

export function* handleDeleteSuccess() {
  yield put(notificationActions.addAlert({
    message: 'Content successfully deleted',
  }))
}

export function* handleCreateAndAddChild(action) {
  const {
    assetCode,
    contentSubType,
    contentType,
    data,
    name,
    parentContent,
    proficiencyIds,
    suppressUpdate,
    teacherEdition,
  } = action

  /**
   * Get current parent from redux.
   * We're treating this as the source of truth, even though the parent is being
   * edited within a form at the time this is happening.
   */
  const parent = yield select(getContentById({
    contentType: parentContent.contentType,
    contentId: parentContent.id,
  }))

  // Create the new child
  const either = yield call(
    dangerouslyCallApi,
    {
      action: { type: actionTypes.CONTENT_SAVE },
      options: {
        method: 'POST',
        body: compose(
          omitIfBlank('assetCode'),
          omitIfBlank('contentSubType'),
          omitIfBlank('data'),
          omitIfBlank('name'),
          omitIfBlank('teacherEdition', true),
          omitIfBlank('contentState'),
        )({
          assetCode,
          contentSubType,
          contentType,
          name,
          data,
          proficiencyIds: assertArray(proficiencyIds),
          teacherEdition,
          // Blocks and the children of Timeline interactives do not have an editable contentState,
          // so they should be published by default
          contentState: (parentContent.contentSubType === INTERACTIVE_TYPE_TIMELINE
            || contentType === CONTENT_TYPE_BLOCK)
            ? CONTENT_STATE_PUBLISHED
            : CONTENT_STATE_DRAFT,
        }),
      },
      passThrough: { suppressAlert: true },
      url: restEndpoint.content,
    },
  )

  if (either.isLeft()) return

  const newChild = either.right()

  if (contentType === 'echo') {
    yield call(provideNewEchoWithFeatureStubs, newChild)
  }

  /**
     * Attach the new child to the parent.
     *
     * This would normally cause Redux to update and would reset any user-altered
     * fields in the edit form.  We don't want that, so I'm using suppressUpdate
     * to prevent Redux from storing the parent's new child association, thus
     * preventing the form component from refreshing.
     *
     * Once this API call is made, Redux will temporarily be out-of-sync with the
     * DB.  I'm relying on the useReduxCallback dispatch that got us here in the
     * first place to update the form so that it contains the new child.
     *
     * This isn't a pattern we should be using and it should only be needed this
     * one time in order to deal with the unique situation of adding children to
     * content within the curriculum forms, while that very content itself may
     * have uncommitted changes.
     */
  const newParent = produce(parent, (draft) => {
    draft.children.push(pick('id')(newChild))
  })
  yield put({
    type: actionTypes.CONTENT_SAVE,
    payload: omit('parent')(newParent),
    suppressAlert: true,
    suppressUpdate,
  })

  const saveResultAction = yield take([success(actionTypes.CONTENT_SAVE), failure(actionTypes.CONTENT_SAVE)])
  const finalResultAction = compose(
    set(
      'type',
      actionSucceeded(saveResultAction) ? success(action.type) : failure(action.type),
    ),
    set('newChildResponse', newChild),
  )(saveResultAction)
  yield put(finalResultAction)
}

export function* handleDelete(action) {
  const { contentId } = action

  yield call(dangerouslyCallApi, {
    action,
    options: {
      method: 'DELETE',
      body: { contentId },
    },
    passThrough: { contentId },
    url: buildUrl(`${restEndpoint.content}/${contentId}`),
  })
}

export function* handleFetchList(action) {
  const { contentSubType, contentType, limit = 20, queryParams } = action

  const search = compose(
    contentSubType ? alter.set.where('contentSubType').is(contentSubType) : identity,
    alter.set.where('contentType').is(contentType),
    alter.set.limit(limit),
  )({})

  yield call(dangerouslyCallApi, {
    action,
    passThrough: { contentType },
    url: buildUrl(restEndpoint.content, { search, ...queryParams }, false),
  })
}

export function* handleFetch(action) {
  const { contentId, contentType, queryParams } = action

  if (contentId === 'create') return

  yield call(dangerouslyCallApi, {
    action,
    passThrough: { contentType },
    url: buildUrl(`${restEndpoint.content}/${contentId}`, queryParams),
  })
}

export function* handleFetchByAssetCode(action) {
  const { assetCode, contentType, queryParams } = action

  const search = compose(
    alter.set.where('assetCode').is(assetCode),
    assert,
  )(queryParams?.search)

  yield call(dangerouslyCallApi, {
    action,
    passThrough: { contentType },
    url: buildUrl(restEndpoint.content, { ...queryParams, search }, false),
  })
}

export function* handlePossibleNewCourse(action) {
  const pathname = get('payload.location.pathname')(action)

  if (pathname === `${contentBuilderUrl}/curriculum/${CONTENT_TYPE_COURSE}/create`) {
    // Here we create the new course, find it's ID and then redirect to the edit screen for it
    const result = yield call(dangerouslyCallApi, {
      action,
      options: {
        method: 'POST',
        body: {
          contentType: CONTENT_TYPE_COURSE,
          name: 'New Course',
        },
      },
      url: `${restEndpoint.content}`,
    })

    if (result.isRight()) {
      const { id } = result.right()

      yield put(replaceHistory(`${contentBuilderUrl}/curriculum/${CONTENT_TYPE_COURSE}/${id}`))
    }
  }
}

export function* handlePossibleNewUnit(action) {
  const pathname = get('payload.location.pathname')(action)

  const re = (/\/content-builder\/curriculum\/unit\/([\w]+)\/create/.exec(pathname))

  if (re) {
    const contentId = re[1]

    // fetch the course
    yield call(handleFetch, { contentId, contentType: CONTENT_TYPE_COURSE })

    // wait until the reducers have it
    yield take([success(actionTypes.CONTENT_FETCH), failure(actionTypes.CONTENT_FETCH)])

    // pull the course
    const course = yield select(getContentById({ contentType: CONTENT_TYPE_COURSE, contentId }))

    if (!course) {
      put({ type: failure('CREATE-NEW-UNIT'), error: new Error(`Could not fetch course ${contentId}`) })
      return
    }

    const { children } = course
    const existingChildIds = children.map(get('id'))

    const courseWithNewUnit = produce(course, (draft) => {
      draft.children.push({
        contentType: CONTENT_TYPE_UNIT,
        contentState: CONTENT_STATE_DRAFT,
        id: newlyAddedChildId,
        name: 'New Unit',
      })
    })

    yield put({
      type: actionTypes.CONTENT_SAVE,
      payload: courseWithNewUnit,
    })

    const result = yield take([success(actionTypes.CONTENT_SAVE), failure(actionTypes.CONTENT_SAVE)])

    const newChildren = get('response.children')(result)

    if (!newChildren) {
      put({ type: failure('CREATE-NEW-UNIT'), error: new Error(`Could not save course ${contentId}`) })
      return
    }

    const newChildIds = newChildren.map(get('id'))
    const newUnitId = difference(newChildIds)(existingChildIds)[0]

    if (!newUnitId) {
      put({ type: failure('CREATE-NEW-UNIT'), error: new Error(`Could not find new unit within ${contentId}`) })
      return
    }

    yield put(replaceHistory(`${contentBuilderUrl}/curriculum/${CONTENT_TYPE_UNIT}/${newUnitId}`))
  }
}

export function* handlePossibleNewEcho(action) {
  const pathname = get('payload.location.pathname')(action)

  if (pathname === `${contentBuilderUrl}/curriculum/${CONTENT_TYPE_ECHO}/create`) {
    // When creating a standalone echo in Library, we create the new echo, mark it as
    // standalone contentSubType, then find it's ID and then redirect to the edit screen for it
    const result = yield call(dangerouslyCallApi, {
      action,
      options: {
        method: 'POST',
        body: {
          contentType: CONTENT_TYPE_ECHO,
          contentSubType: CONTENT_SUB_TYPE_STANDALONE,
          name: 'New Echo',
        },
      },
      url: `${restEndpoint.content}`,
    })

    if (result.isRight()) {
      const newEcho = result.right()
      const { id } = newEcho
      yield call(provideNewEchoWithFeatureStubs, newEcho)
      yield put(replaceHistory(`${contentBuilderUrl}/curriculum/${CONTENT_TYPE_ECHO}/${id}`))
    }
  }
}

export function* handlePossibleNewSource(action) {
  const pathname = get('payload.location.pathname')(action)

  if (pathname === `${contentBuilderUrl}/curriculum/${CONTENT_TYPE_SOURCE}/create`) {
    // Here we create the new source, find it's ID and then redirect to the edit screen for it
    const result = yield call(dangerouslyCallApi, {
      action,
      options: {
        method: 'POST',
        body: {
          contentType: CONTENT_TYPE_SOURCE,
          name: 'New Source',
        },
      },
      url: `${restEndpoint.content}`,
    })

    if (result.isRight()) {
      const { id } = result.right()

      yield put(replaceHistory(`${contentBuilderUrl}/curriculum/${CONTENT_TYPE_SOURCE}/${id}`))
    }
  }
}

export function* handlePossibleNewInteractiveWithChildren(action) {
  /**
   * Some complex interactives can have child pages, or sets of pages.
   *
   * We need an id before children can be added, so much like with courses and
   * sources, we'll need to automatically save the interactive and redirect to
   * the edit page.
   */

  const interactivesWithPages = [
    INTERACTIVE_TYPE_FLIPBOOK,
    INTERACTIVE_TYPE_TIMELINE,
  ]

  const route = yield select(matchPathSelector({ path: `${contentBuilderUrl}/interactive/:contentSubType/create` }))

  if (route && interactivesWithPages.includes(route.params.contentSubType)) {
    // Here we create the new interactive, find it's ID and then redirect to the edit screen for it
    const result = yield call(dangerouslyCallApi, {
      action,
      options: {
        method: 'POST',
        body: {
          contentType: CONTENT_TYPE_INTERACTIVE,
          contentSubType: route.params.contentSubType,
          name: `New ${capitalize(route.params.contentSubType)}`,
        },
      },
      url: `${restEndpoint.content}`,
    })

    if (result.isRight()) {
      const { id } = result.right()

      yield put(replaceHistory(`${contentBuilderUrl}/interactive/${route.params.contentSubType}/${id}`))
    }
  }
}

function* handleGuardAgainstUnwantedTeDisplay() {
  // this gets called frequently, so it's set up to bail early

  // there's no point in doing anything if presenter mode is not enabled
  const presenterModeEnabled = yield select(getLocalSetting(TOGGLE_STATE_PRESENTER_MODE))
  if (!presenterModeEnabled) return

  // Only proceed when within the content viewer
  const { currentLocation, queryParams } = yield select(stateRouting)
  if (!currentLocation.pathname.startsWith(contentViewerUrl)) return

  // Figure out if the current content or any of its ancestors are TE
  const content = yield select(getCurrentViewContent)
  const contentId = content?.id
  if (!contentId) return
  const ancestry = yield select(getContentAncestry({ contentId }))
  const isTe = yield select(getAnyAncestorIsTe({ content: ancestry }))
  if (!isTe) return

  // navigate to the sequentially next page, retaining any query params
  const { children, id: chapterId } = yield select(getViewerTopLevelContent)
  const parentId = get('parent.id')(content)
  const parentContent = yield select(getContentById({ contentId: parentId }))

  const firstSiblingId = compose(
    get('id'),
    first,
    get('children'),
  )(parentContent)
  const lastSiblingId = compose(
    get('id'),
    last,
    get('children'),
  )(parentContent)

  // `includeTeacherEdition` needs to be true, otherwise siblings to the TE content won't be found
  const { prev } = yield select(getNextPrevForContentViewer({
    contentId: firstSiblingId,
    includeTeacherEdition: true,
  }))
  const { next } = yield select(getNextPrevForContentViewer({
    contentId: lastSiblingId,
    includeTeacherEdition: true,
  }))

  let redirectContent = next || prev

  if (!redirectContent) {
    // If no next or previous are found, redirect to first non-TE section in the chapter
    const fullChapterContent = yield all(children.map(child => select(getContentById({ contentId: child.id }))))
    const nonTeChapterContent = fullChapterContent
      .filter(child => !child.teacherEdition && child.contentSubType !== CONTENT_SUBTYPE_CHAPTER_SUMMARY)

    redirectContent = compose(
      first,
      get('children'),
      first,
    )(nonTeChapterContent)
  }

  const newUrl = `${contentViewerUrl}/${CONTENT_TYPE_CHAPTER}/${chapterId}/${redirectContent.contentType}/${redirectContent.id}`
  yield put(replaceHistory(buildUrl(newUrl, queryParams, false)))
}

/* istanbul ignore next line */
function* contentSaga() {
  yield takeEvery(actionTypes.CONTENT_CREATE_AND_ADD_CHILD, handleCreateAndAddChild)
  yield takeEvery(actionTypes.CONTENT_DELETE, handleDelete)
  yield takeEvery(actionTypes.CONTENT_FETCH_LIST, handleFetchList)
  yield takeEvery(actionTypes.CONTENT_FETCH, handleFetch)
  yield takeLatest(actionTypes.CONTENT_FETCH_BY_ASSET_CODE, handleFetchByAssetCode)
  yield takeLatest(actionTypes.CONTENT_SAVE, handleSave)
  yield takeLatest(actionTypes.LOCAL_SETTINGS_SET, handleGuardAgainstUnwantedTeDisplay)
  yield takeLatest(LOCATION_CHANGE, handlePossibleNewCourse)
  yield takeLatest(LOCATION_CHANGE, handlePossibleNewEcho)
  yield takeLatest(LOCATION_CHANGE, handlePossibleNewInteractiveWithChildren)
  yield takeLatest(LOCATION_CHANGE, handlePossibleNewSource)
  yield takeLatest(LOCATION_CHANGE, handlePossibleNewUnit)
  yield takeLatest(success(actionTypes.CONTENT_DELETE), handleDeleteSuccess)
  yield takeLatest(success(actionTypes.CONTENT_FETCH), handleGuardAgainstUnwantedTeDisplay)
  yield takeLatest(success(actionTypes.CONTENT_SAVE), handleSaveSuccess)
}

export default contentSaga
