import { call, put, takeEvery, takeLatest, takeLeading } from 'redux-saga/effects'
import actionTypes from 'reducers/actionTypes'
import { restEndpoint } from 'reducers/utils'
import { actions as notificationActions } from 'reducers/notifications'
import { omit } from 'fp/objects'
import { buildUrl } from 'fp/internet'
import { success } from './utils'
import { dangerouslyCallApi } from './api'

export function* handleDelete(action) {
  const { reactionId } = action

  yield call(dangerouslyCallApi, {
    action,
    options: {
      method: 'DELETE',
      body: {},
    },
    passThrough: { reactionId },
    url: buildUrl(`${restEndpoint.reactions}/${reactionId}`),
  })
}

export function* handleDeleteSuccess() {
  yield put(notificationActions.addAlert({
    message: 'Review successfully deleted',
  }))
}

export function* handlePost(action) {
  yield call(dangerouslyCallApi, {
    action,
    options: {
      method: 'POST',
      body: omit('type')(action),
    },
    url: restEndpoint.reactions,
  })
}

export function* handlePostSuccess() {
  yield (put(notificationActions.addAlert({
    message: 'Your review has been saved.',
  })))
}

/* istanbul ignore next line */
function* reactionSaga() {
  yield takeEvery(actionTypes.REACTION_DELETE, handleDelete)
  yield takeLeading(actionTypes.REACTION_POST, handlePost)
  yield takeLatest(success(actionTypes.REACTION_DELETE), handleDeleteSuccess)
  yield takeLeading(success(actionTypes.REACTION_POST), handlePostSuccess)
}

export default reactionSaga
