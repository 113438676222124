import { success } from 'sagas/utils'
import actionTypes from './actionTypes'
import { createReducer, handleFetchListSuccess, listReducerInitialState, updateRemoved } from './utils'

/**
 * Reactions are listed by interacTION id, not content or interacTIVE ids, though
 * you can look them up easily enough via selectors if need be.
 */

export const actions = {
  deleteReaction: ({ reactionId }) => ({
    type: actionTypes.REACTION_DELETE,
    reactionId,
  }),
}

const handleRemove = (state, { passThrough }) => updateRemoved(state, { id: passThrough?.action?.reactionId })

const reactions = createReducer(
  listReducerInitialState(),
  {
    [success(actionTypes.REACTION_DELETE)]: handleRemove,
    [success(actionTypes.REACTIONS_RECEIVED)]: handleFetchListSuccess,
  },
)

export default reactions
