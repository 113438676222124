import { NamedProvider as ThemeProvider } from 'styling/theming/ThemeProvider'
import { A11yProvider } from 'core/a11y'
import { componentShape } from 'core/shapes'

/**
 * NOTE:
 * The way `react-confirm` works is by injecting a new, root react element into the
 * DOM.
 * What that means for us is there is *NO* context available to anything we put into
 * the dialog, including the dialog itself.  We're wrapping the dialog here with a
 * duplicate ThemeProvider so that the look-n-feel carries over, using whatever the
 * current theme happens to be.
 *
 * We can't access any Redux related information from here on down.  Any such context
 * information would need to be passed in.  Considering that this is just a simple
 * confirmation dialog, there should never be a need to access the store from here
 * anyhow.
 */
const ThemedPortal = /* istanbul ignore next */ ({ children }) => {
  const theme = localStorage.getItem('theme')

  return (
    // <StyledEngineProvider injectFirst> TODO: might be needed when portalling
    <A11yProvider>
      <ThemeProvider themeName={theme?.name || 'hss'}>
        {children}
      </ThemeProvider>
    </A11yProvider>
    // </StyledEngineProvider>
  )
}

ThemedPortal.propTypes = {
  children: componentShape.isRequired,
}

export default ThemedPortal
