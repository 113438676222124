import { call, debounce, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { compose } from 'redux'
import { INTERACTION_STATE_OPEN, INTERACTION_TYPE_PAGE_VIEW } from 'core/consts'
import actionTypes from 'reducers/actionTypes'
import { restEndpoint } from 'reducers/utils'
import { buildUrl } from 'fp/internet'
import { actions as notificationActions } from 'reducers/notifications'
import { omit } from 'fp/objects'
import { identity } from 'fp/utils'
import { alter, assert } from '../search/squery'
import { dangerouslyCallApi } from './api'
import { success } from './utils'

export function* handleDelete(action) {
  const { interactionId } = action
  yield call(dangerouslyCallApi, {
    action,
    options: {
      method: 'DELETE',
      body: {},
    },
    url: `${restEndpoint.interactions}/${interactionId}`,
  })
}

export function* handleFetch(action) {
  const {
    assignmentId,
    contentId,
    interactionType,
    limit = 999,
    queryParams,
    sharedAssignmentId,
    userAssignmentId,
    userId,
  } = action

  const search = compose(
    userAssignmentId ? alter.set.where('userAssignmentId').is(userAssignmentId) : identity,
    assignmentId ? alter.set.where('assignmentId').is(assignmentId) : identity,
    userId ? alter.set.where('userId').is(userId) : identity,
    interactionType ? alter.set.where('interactionType').is(interactionType) : identity,
    alter.set.modifier('forRootContentId').is(contentId),
    sharedAssignmentId ? alter.set.modifier('sharedForAssignmentId').is(sharedAssignmentId) : identity,
    alter.set.limit(limit),
  )(assert())

  yield call(dangerouslyCallApi, {
    action,
    url: buildUrl(restEndpoint.interactions, { search, ...queryParams }, false),
  })
}

export function* handlePost(action) {
  const method = action.id ? 'PATCH' : 'POST'
  const url = restEndpoint.interactions + (method === 'PATCH'
    ? `/${action.id}`
    : '')

  yield call(dangerouslyCallApi, {
    action,
    options: {
      method,
      body: omit('type')(action),
    },
    passThrough: { suppressAlert: action.suppressAlert },
    url,
  })
}

export function* handlePostSuccess(action) {
  const { passThrough, response: { interactionType, state } } = action
  const { suppressAlert = false } = passThrough || {}

  if (!suppressAlert
    && state !== INTERACTION_STATE_OPEN
    && interactionType !== INTERACTION_TYPE_PAGE_VIEW
  ) {
    yield (put(notificationActions.addAlert({
      message: 'Your data has been saved.',
    })))
  }
}

/* istanbul ignore next line */
function* interactionSaga() {
  yield takeEvery(actionTypes.INTERACTION_DELETE, handleDelete)
  yield takeEvery(actionTypes.INTERACTION_FETCH_LIST, handleFetch)
  yield debounce(500, actionTypes.INTERACTION_POST, handlePost)
  yield takeLatest(success(actionTypes.INTERACTION_POST), handlePostSuccess)
}

export default interactionSaga
