import { border, margin, padding } from 'polished'
import { curryRight } from 'fp/utils'
import { important } from '../mixins'

const overrides = ({ mixins: { absWidth, importantRem, rem, size }, palette, typography: { variants } }) => {
  const columnHeaderStyle = {
    textAlign: 'left',
    ...variants['nav-item-uppercase-semibold'],
    background: palette.grey[5],
  }

  const borderWidth = 1
  const lightBorderColor = palette.border[1]
  const lightBorder = curryRight(border, borderWidth, 'solid', lightBorderColor)
  const defaultBorder = curryRight(border, borderWidth, 'solid', palette.border[0])
  const darkBorder = curryRight(border, borderWidth, 'solid', palette.primary[700])

  const dataTableStyle = {
    '.MuiFormControl-fullWidth': { minWidth: 220 },
  }

  const diagonalTableCellPadding = {
    x: 1.7,
    y: 1,
  }

  return ({
    MuiTableContainer: {
      styleOverrides: {
        root: {
          table: {
            ...defaultBorder('bottom'),
            borderSpacing: 0,
            width: '100%',

            tr: {
              // Using a class instead of nth-child because we don't
              // want zebra stripes inside expanded detail rows.
              '&.even-row': {
                background: palette.background.paper,
              },
            },

            'th, td': {
              padding: rem(1.25, 2, 1.25, 0),
            },
            th: {
              textAlign: 'left',
            },

            a: {
              color: 'inherit',
            },

            thead: {
              ...columnHeaderStyle,
              th: {
                cursor: 'default',
                fontWeight: 'inherit',
                ...defaultBorder('top'),
                '&:first-child': defaultBorder('left'),
                '&:last-child': defaultBorder('right'),

                '&:first-of-type': {
                  paddingLeft: borderWidth,
                },

                '.sort-button': {
                  ...columnHeaderStyle,
                  background: 'none',
                  border: 'none',
                  outline: 'none',
                  justifyContent: 'flex-start',
                  padding: 0,
                  textDecoration: 'none',
                  width: '100%',

                  '&:focus, &:hover': {
                    color: 'inherit',
                    svg: {
                      color: 'inherit',
                    },
                  },
                  '&:active': {
                    boxShadow: 'none',
                  },
                },
              },
            },
            tbody: {
              'th, td': {
                ...defaultBorder('top'),
                '&:first-child': defaultBorder('left'),
                '&:last-child': defaultBorder('right'),
              },
              th: {
                fontWeight: 'inherit',
              },
              tr: {
                // This class is applied to the rows with expander buttons, when they're expanded.
                '&.expanded': {
                  'th, td': {
                    ...darkBorder('top'),
                    '&:first-child': darkBorder('left'),
                    '&:last-child': darkBorder('right'),
                  },
                },
                // This class is applied to the rows that appear
                // underneath the `.expanded` row when an expander button is pressed.
                '&.detail-row': {
                  td: {
                    borderTop: 'none',
                    verticalAlign: 'top',
                    '&.detail-rows-end': {
                      ...darkBorder('bottom'),
                      paddingBottom: rem(1),
                    },
                    // Not applying a left border here, because some cells can span multiple rows.
                  },
                },
              },
              // We're assuming the first detail cell spans all detail rows.
              // Technically the DataTable component doesn't have this limitation,
              // but we'll figure out how to remove this assumption if we have to.
              'tr.expanded + tr.detail-row': {
                'td:first-child': darkBorder('left'),
                'td:last-child': darkBorder('right'),
              },
              'tr.detail-row + tr.detail-row': {
                td: {
                  borderLeft: 'none',
                  borderRight: 'none',
                },
              },
              // Prevent a double border between two adjacent expanded rows.
              // The prior .detail-row will have a bottom border because of .detail-rows-end.
              'tr.detail-row + tr': {
                'th, td': {
                  borderTop: 'none',
                },
              },
            },
            '.row-selector': {
              width: rem(8),
            },
            '&:not(.selectable-rows)': {
              thead: {
                'th:first-of-type:not(.diagonal)': {
                  paddingLeft: `calc(${borderWidth}px + 2rem)`,
                },
              },
              tbody: {
                tr: {
                  'th, td': {
                    '&:first-child': {
                      paddingLeft: importantRem(2),
                    },
                  },
                },
                // Again, assuming the first detail cell spans all rows.
                'tr.detail-row + tr.detail-row': {
                  'td:first-child': {
                    paddingLeft: 0,
                  },
                },
              },
            },
            img: {
              maxWidth: rem(30),
            },
            '&.has-diagonal-header': {
              marginTop: rem(20),
              '&.has-head-data-rows': {
                marginTop: rem(24),
              },
              borderColor: lightBorderColor,
              width: 'auto',
              thead: {
                th: {
                  ...lightBorder('top'),
                  '&:not(.diagonal)': {
                    '&:first-of-type': lightBorder('left'),
                    background: palette.grey[5],
                    ...padding(0, rem(1.1)),
                    height: rem(5),
                    fontWeight: 500,
                    position: 'relative',
                    button: {
                      fontWeight: 500,
                    },
                    '&[scope=row]': {
                      textAlign: 'right',
                    },
                    '&:not([scope=row])::after': {
                      content: '""',
                      height: '100%',
                      width: rem(6),
                      background: palette.grey[5],
                      ...lightBorder('top'),
                      position: 'absolute',
                      left: '100%',
                      top: -1,
                      bottom: 0,
                      zIndex: -1,
                    },
                  },
                  '&.diagonal': (() => {
                    const columnWidth = rem(12)
                    return ({
                      ...absWidth(columnWidth),
                      position: 'relative',
                      textTransform: 'none',
                      padding: 0,
                      '.skewed-container': {
                        ...size(rem(24), columnWidth),
                        transformOrigin: 'bottom left',
                        transform: 'skew(-45deg, 0)',
                        ...lightBorder('left'),
                        ...lightBorder('top'),
                        borderBottom: 'none',
                        position: 'absolute',
                        bottom: 0,
                        zIndex: 1,
                        background: palette.background.default,
                        '.rotated-content': {
                          transformOrigin: 'bottom left',
                          transform: 'translateX(10rem) skew(45deg, 0) rotate(-45deg)',
                          position: 'absolute',
                          bottom: 0,
                          display: 'flex',
                          flexDirection: 'row-reverse',
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                          width: rem(26),
                          ...padding(rem(1.2), 0, rem(1.2), rem(1.2)),
                          '> *:not(.menu)': {
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          },
                          '.menu': {
                            transform: 'rotate(-45deg)',
                            ...margin(null, rem(1.2), rem(-1.2), rem(-1.2)),
                          },
                        },
                      },
                      '&:last-of-type .skewed-container': {
                        ...lightBorder('right'),
                      },
                    })
                  })(),
                },
                'th:not(.diagonal), td': lightBorder('top'),
                td: {
                  ...lightBorder('left'),
                  paddingRight: 0,
                  '&:last-of-type': lightBorder('right'),
                  '&.blank': {
                    border: 'none',
                  },
                  '&.divider': {
                    background: palette.grey[5],
                  },
                },
                'tr:last-of-type': {
                  '.sort-button': {
                    paddingRight: 0,
                    justifyContent: 'center',
                  },
                },
                'th:first-of-type .sort-button': {
                  justifyContent: 'flex-start',
                },
              },
              tbody: {
                'th, td': {
                  ...lightBorder('left'),
                  ...padding(rem(diagonalTableCellPadding.y), rem(diagonalTableCellPadding.x)),
                },
              },
              td: {
                textAlign: 'center',
                '&:last-child': lightBorder('right'),
              },
            },
          },
        },
      },

      variants: [
        {
          props: { variant: 'data-table' },
          style: dataTableStyle,
        },

        {
          props: { variant: 'gradebook' },
          style: {
            ...dataTableStyle,
            table: {
              '&.has-diagonal-header': {
                thead: {
                  th: {
                    '&.diagonal': {
                      '.skewed-container': {
                        '.rotated-content': {
                          '.standard-item-popper': {
                            transform: important('translateX(-35rem) rotate(45deg)'),
                          },

                          '> *:not(.menu)': {
                            overflow: 'visible',
                            whiteSpace: 'wrap',
                          },
                        },
                      },
                    },
                  },
                },
              },
              tbody: {
                tr: {
                  height: rem(9),
                },
                'td.gradebook-cell': {
                  position: 'relative',
                  padding: 0,
                  button: {
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    boxShadow: 'none',
                  },
                  '.tab-mode-enabled &': {
                    a: {
                      '&:focus': {
                        outlineOffset: 0,
                        textUnderlineOffset: rem(0.3),
                        textDecorationThickness: rem(0.3),
                      },
                    },
                  },
                  a: {
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center',
                    position: 'relative',
                    textUnderlineOffset: rem(0.3),
                    padding: rem(3),
                  },
                  'button, a': {
                    '.grading-alert': {
                      color: 'inherit',
                      position: 'absolute',
                      right: rem(1),
                      top: '50%',
                      transform: 'translateY(-50%)',
                    },
                  },
                },
              },
            },
          },
        },
      ],
    },
  })
}

export default overrides
