import { compose } from 'redux'
import { Book, XCircle } from 'react-feather'
import Icon from '@mui/material/Icon'
import Google from 'hss/images/google.svg'
import {
  IconLinkToTableCell,
  SimpleCell,
  SimpleDateCell,
  SimplePercentageCell,
  SimpleRelativeTimeCell,
  asDetail,
} from 'common/tables/DataTable/Cells'
import { get } from 'fp/objects'
import {
  ASSIGNMENT_TARGET_GROUP,
  ASSIGNMENT_TARGET_STUDENT,
} from 'core/consts'
import BookFilled from 'hss/images/indicators/book-filled.svg'
import { groupsUrl, studentsUrl } from 'routing/consts'
import { fallbackTo, whenPresent } from 'fp/utils'
import { prefix } from 'fp/strings'
import CheckCircle from 'hss/images/indicators/check-closed-circle.svg'

const firstAndLastName = ({ firstName, lastName } = {}) => `${firstName} ${lastName}`

const targetAccessors = {
  [ASSIGNMENT_TARGET_GROUP]: get('name'),
  [ASSIGNMENT_TARGET_STUDENT]: firstAndLastName,
}

export const assignmentStatusCell = {
  accessorKey: 'isActive',
  cell: ({ getValue }) => getValue()
    ? (
      <Icon
        aria-hidden={false}
        aria-label="Active"
        role="img"
      >
        <CheckCircle />
      </Icon>
    )
    : (
      <Icon
        aria-hidden={false}
        aria-label="Inactive"
        role="img"
      >
        <XCircle />
      </Icon>
    ),
  header: 'Status',
  id: 'isActive',
}

export const assignmentTargetCell = {
  accessorFn: ({ targetTypeId, target }) => whenPresent(
    targetAccessors[targetTypeId],
    target,
  ),
  header: 'Target',
  id: 'name',
  meta: { sortField: 'name' },
}

export const avgAssessmentScoreCell = SimplePercentageCell({
  header: 'Avg. Assessment Score',
  id: 'averageAssessmentScore',
})

export const avgInteractiveScoreCell = SimplePercentageCell({
  header: 'Avg. Interactive Score',
  id: 'averageInteractiveScore',
})

export const avgPeerScoreCell = SimplePercentageCell({
  header: 'Avg. Peer Score',
  id: 'avgPeerScore',
})

export const completedDateCell = SimpleDateCell({
  header: 'Completed On',
  id: 'submittedDate',
})

export const createdByCell = asDetail({
  accessorFn: compose(
    firstAndLastName,
    fallbackTo({ firstName: '', lastName: '' }),
    get('createdBy'),
  ),
  header: 'Created By',
  id: 'createdBy',
})

export const createdDateCell = SimpleDateCell({
  header: 'Created On',
  id: 'createdDate',
})

export const deadlineCell = SimpleRelativeTimeCell({
  accessorFn: get('endDate'),
  header: 'Deadline',
  id: 'deadline',
  meta: { sortFieldName: 'endDate' },
})

export const endDateCell = SimpleDateCell({
  accessorFn: row => row.assignment
    ? get('assignment.endDate')(row)
    : get('endDate')(row),
  header: 'End',
  id: 'endDate',
})

export const firstNameCell = (id = 'firstName') => SimpleCell({
  header: 'First Name',
  id,
})

export const lastNameCell = (id = 'lastName') => SimpleCell({
  header: 'Last Name',
  id,
})

export const lastOnlineCell = SimpleRelativeTimeCell({
  header: 'Last Online',
  id: 'lastOnline',
  // TODO: enable once the api allows sorting by calculated fields
  enableSorting: false,
})

export const modifiedDateCell = SimpleDateCell({
  header: 'Last Modified',
  id: 'modifiedDate',
})

export const nextDueDateCell = SimpleDateCell({
  header: 'Next Due Date',
  id: 'nextDueDate',
})

export const numGroupsCell = SimpleCell({
  header: 'Groups',
  id: 'groupIds.length',
})

export const numStudentsCell = SimpleCell({
  header: 'Students',
  id: 'studentsCount',
})

export const numTeachersCell = SimpleCell({
  header: 'Teachers',
  id: 'teachersCount',
})

export const numTeacherIdsCell = SimpleCell({
  header: 'Teachers',
  id: 'teacherIds.length',
})

export const openAssignmentsCell = SimpleCell({
  header: 'Open Assignments',
  id: 'openAssignments',
})

export const proficiencyCell = SimpleCell({
  header: 'Proficiency',
  id: 'proficiencyId',
  // TODO: enable once the api exposes proficiency text
  enableSorting: false,
})

export const progressCell = SimplePercentageCell({
  header: 'Completion',
  id: 'progress',
  sortType: 'number',
})

export const scoreCell = SimpleCell({
  header: 'Score',
  id: 'score',
  meta: { sortFieldName: 'score' },
  // TODO: enable once the api allows sorting by calculated fields
  enableSorting: false,
})

export const startDateCell = SimpleDateCell({
  header: 'Start',
  id: 'startDate',
})

export const statusCell = SimpleCell({
  header: 'Status',
  id: 'status',
})

// export const submittedDateCell = SimpleDateCell({
//   header: 'Submitted',
//   id: 'submittedDate',
// })

export const viewGoogleIntegrationCell = {
  accessorFn: () => 'View Google Settings',
  cell: IconLinkToTableCell(compose(
    prefix(`${groupsUrl}/`),
    get('id'),
  ), Google),
  enableSorting: false,
  disableFilters: true,
  header: 'Integrations',
  id: 'integrations',
}

export const viewGroupGradebookCell = {
  accessorFn: () => 'View Gradebook',
  cell: IconLinkToTableCell(({ id }) => `${groupsUrl}/${id}/grades`, Book, BookFilled),
  enableSorting: false,
  disableFilters: true,
  header: 'Grades',
  id: 'grades',
}

export const viewStudentGradebookCell = {
  accessorFn: () => 'View Gradebook',
  cell: IconLinkToTableCell(({ id }) => `${studentsUrl}/${id}/grades`, Book, BookFilled),
  enableSorting: false,
  disableFilters: true,
  header: 'Grades',
  id: 'grades',
}
