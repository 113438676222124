import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import Accordion from '@mui/material/Accordion'
import Tooltip from '@mui/material/Tooltip'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Typography from '@mui/material/Typography'
import { ChevronDown, Trash } from 'react-feather'
import { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import { ABILITY_TEACHER_INTERFACE } from 'shared/consts'
import { actions as reactionActions } from 'reducers/reactions'
import { actions as userActions } from 'reducers/users'
import { numberOrString, rubricCriteriaShape } from 'core/shapes'
import { isEmptyString } from 'fp/strings'
import { importantPx } from 'styling/theming/base/mixins'
import { noop } from 'fp/utils'
import { getUserById } from 'selectors/users'
import { formatDateStrShort } from 'locale/i18n'
import useAbilityChecker from 'hooks/useAbilityChecker'
import SimpleStarRating from './SimpleStarRating'

const ReviewAccordion = (props) => {
  const {
    accordionProps,
    accordionSummaryProps,
    disabled = false,
    displayMode = 'star',
    expandable = false,
    featured = false,
    onChange,
    ratingOrRatingId,
    readOnly = false,
    render,
    review: {
      dateCreated,
      id: reactionId,
      peerInteraction: { userId: peerUserId } = {},
      reactionData: { comment = '' },
      userId,
    } = { reactionData: {} },
    rubricCriteria,
    title = 'Anonymous',
  } = props

  const dispatch = useDispatch()
  const [expanded, setExpanded] = useState(false)
  const has = useAbilityChecker()
  const hasContentViewTeach = has(ABILITY_TEACHER_INTERFACE)

  // peerUserId is used when this student gives a review to another student
  // userId is used when this student receives a review
  const userToDisplay = peerUserId || userId

  useEffect(() => {
    if (hasContentViewTeach && userToDisplay) {
      dispatch(userActions.fetchUserById({ userId: userToDisplay }))
    }
  }, [dispatch, hasContentViewTeach, userToDisplay])

  const { firstName = '', lastName = '' } = useSelector(getUserById({ userId: userToDisplay })) || {}

  const fullName = hasContentViewTeach
    ? `${firstName} ${lastName}`.trim()
    : null

  const tooltip = 'Removes this review. This review will no longer be seen by the reviewer or the student reviewed and will not be used to calculate the average score.'

  const handleRemove = () => {
    dispatch(reactionActions.deleteReaction({ reactionId }))
  }

  return (
    <Accordion
      {...{
        expanded,
        onChange: expandable ? (_, exp) => setExpanded(exp) : null,
        'data-featured': featured ? 'true' : 'false',
        variant: 'peer-review',
        ...accordionProps,
      }}
    >
      <AccordionSummary
        data-disabled={disabled ? 'true' : 'false'}
        data-featured={featured ? 'true' : 'false'}
        expandIcon={expandable ? <ChevronDown /> : null}
        variant="peer-review"
        {...accordionSummaryProps}
      >
        <Typography
          sx={{ width: '50%', flexShrink: 0 }}
          variant="large-semibold"
        >
          {fullName || title}
        </Typography>

        {displayMode === 'numeric' && (
          <Typography variant="large-semibold">
            {Number.isNaN(ratingOrRatingId)
              ? '–'
              : Number(ratingOrRatingId).toFixed(1)}
          </Typography>
        )}

        {displayMode === 'star' && (
          <SimpleStarRating
            disabled={disabled}
            onChange={onChange}
            readOnly={readOnly}
            rubricCriteria={rubricCriteria}
            value={ratingOrRatingId}
          />
        )}
      </AccordionSummary>

      <AccordionDetails>
        {Boolean(!isEmptyString(comment) || render === noop || hasContentViewTeach) && (
          <>
            {Boolean(hasContentViewTeach) && (
              <Box margin={importantPx(8)}>
                <Typography variant="footnote">Submitted on {formatDateStrShort(dateCreated)}</Typography>
              </Box>
            )}
            <Box
              alignItems="flex-start"
              component="p"
              display="flex"
              justifyContent="space-between"
              margin={importantPx(8)}
            >
              <span>{comment || <i>no comment provided</i>}</span>
              {Boolean(hasContentViewTeach) && (
                <Tooltip title={tooltip}>
                  <IconButton
                    aria-label={tooltip}
                    data-testid="remove-comment-btn"
                    onClick={handleRemove}
                    style={{ padding: 0 }}
                  >
                    <Trash />
                  </IconButton>
                </Tooltip>
              )}

            </Box>
          </>
        )}

        {render()}
      </AccordionDetails>
    </Accordion>
  )
}

ReviewAccordion.propTypes = {
  accordionProps: PropTypes.object,
  accordionSummaryProps: PropTypes.object,
  disabled: PropTypes.bool,
  displayMode: PropTypes.oneOf(['numeric', 'star']),
  expandable: PropTypes.bool,
  featured: PropTypes.bool,
  onChange: PropTypes.func,
  ratingOrRatingId: numberOrString,
  readOnly: PropTypes.bool,
  render: PropTypes.func.isRequired,
  review: PropTypes.object,
  rubricCriteria: rubricCriteriaShape,
  title: PropTypes.string,
}

export default ReviewAccordion
